<editable-list 
    [itemsFormArray]="itemsFormArray"
    [addLabel]="addLabel" 
    [sortable]="sortable"
    [focusOnEnter]="focusOnEnter"
    [fullWidthList]="fullWidthList"
    (onAdd)="onAdd.emit($event)" 
    (onDelete)="onDelete.emit($event)"
    (onValidityChange)="onValidityChange.emit($event)">
    <ng-template let-item="item" let-goToNext="goToNext">
        <editable-list-template (onInputEnter)="goToNext($event)">
            <editable-list-input 
                [inputControl]="item?.get(inputKey)"
                (inputChange)="updateSuggestions($event)"
                [suggestions]="filteredSuggestions"
                autocomplete="{{autocomplete}}"
                placeholder="{{valuePlaceholder}}"
                [required]="required"
                (onFocus)="handleFocus($event)" 
                (onBlur)="onBlur.emit($event)">
            </editable-list-input>
        </editable-list-template>
    </ng-template>
</editable-list>
