<div
    class="config-area"
    [ngSwitch]="params.type"
    [ngClass]="className"
    cdkScrollable
>
    <generic-summary-stats-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        *ngSwitchCase="'univariate_summary'"
    >
    </generic-summary-stats-card-config>

    <generic-summary-stats-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        *ngSwitchCase="'bivariate_summary'"
    >
    </generic-summary-stats-card-config>

    <categorical-histogram-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'categorical_histogram'"
    >
    </categorical-histogram-card-config>

    <bivariate-box-plot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'bivariate_box_plot'"
    >
    </bivariate-box-plot-card-config>

    <univariate-frequency-table-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'univariate_frequency_table'"
    >
    </univariate-frequency-table-card-config>

    <quantiles-table-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'quantile_table'"
    >
    </quantiles-table-card-config>

    <numerical-histogram-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'numerical_histogram'"
    >
    </numerical-histogram-card-config>

    <abstract-2d-pivot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'bivariate_frequency_table'"
    >
    </abstract-2d-pivot-card-config>

    <abstract-2d-pivot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'bivariate_histogram'"
    >
    </abstract-2d-pivot-card-config>

    <abstract-2d-pivot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'mosaic_plot'"
    >
    </abstract-2d-pivot-card-config>

    <scatter-plot-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'scatter_plot'"
    >
    </scatter-plot-card-config>

    <univariate-header-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'univariate_header'"
    ></univariate-header-card-config>

    <bivariate-header-card-config
        [params]="params"
        (validityChange)="validityChange.emit($event)"
        (paramsChange)="paramsChange.emit($event)"
        *ngSwitchCase="'bivariate_header'"
    ></bivariate-header-card-config>

    <tztest1-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'ttest_ztest_1samp'"
    ></tztest1-samp-card-config>

    <sign-test1-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'sign_test_1samp'"
    ></sign-test1-samp-card-config>

    <shapiro-normality-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'shapiro'"
    ></shapiro-normality-test-card-config>

    <abstract-2-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'ttest_2samp'"
    ></abstract-2-samp-card-config>

    <abstract-2-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'mood_test_2samp'"
    ></abstract-2-samp-card-config>

    <abstract-2-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'ks_test_2samp'"
    ></abstract-2-samp-card-config>

    <abstract-n-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'oneway_anova'"
    ></abstract-n-samp-card-config>

    <abstract-n-samp-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'mood_nsamp'"
    ></abstract-n-samp-card-config>

    <abstract-pairwise-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'pairwise_ttest'"
    ></abstract-pairwise-test-card-config>

    <abstract-pairwise-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
    *ngSwitchCase="'pairwise_mood'"
    ></abstract-pairwise-test-card-config>

    <chi2-ind-test-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'chi2_independence_test'"
    ></chi2-ind-test-card-config>

    <fit-distribution-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'fit_distribution'"
    ></fit-distribution-card-config>

    <fit-2d-distribution-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'fit_2d_distribution'"
    ></fit-2d-distribution-card-config>

    <fit-curve-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'fit_curve'"
    ></fit-curve-card-config>

    <multivariate-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'correlation_matrix'"
    ></multivariate-card-config>

    <multivariate-card-config
        [params]="params"
        (paramsChange)="paramsChange.emit($event)"
        (validityChange)="validityChange.emit($event)"
        *ngSwitchCase="'pca'"
    ></multivariate-card-config>

    <ng-container *ngSwitchDefault>NOT IMPLEMENTED</ng-container>
</div>