<div class="editable-list" [class.editable-list--full-width]="fullWidthList">
    <!-- Main block -->
    <ul *ngIf="!sortable" class="editable-list__items">
        <li #itemLi *ngFor="let item of itemsControls; let index = index;" class="common-styles-only editable-list__item" [class.editable-list__item--divider]="hasDivider">
            <ng-container *ngTemplateOutlet="itemTemplate, context: { item: item, index: index }"></ng-container>
        </li>
    </ul>

    <!-- Main block when drag and drop is enabled -->
    <ul *ngIf="sortable" class="editable-list__items" cdkDropList (cdkDropListDropped)="drop($event)">
        <li #itemLi *ngFor="let item of itemsControls; let index = index;" class="common-styles-only editable-list__item"  [class.editable-list__item--divider]="hasDivider" cdkDrag>
            <editable-list-drag cdkDragHandle></editable-list-drag>
            <ng-container *ngTemplateOutlet="itemTemplate, context: { item: item, index: index }"></ng-container>
        </li>
    </ul>

    <!-- Add button footer -->
    <div class="editable-list__add">
        <button type="button" class="btn btn--primary btn--text editable-list__add-label" (click)="add()">
            <i class="icon-plus"></i>{{addLabel}}
        </button>
    </div>
</div>

<!-- Row template -->
<ng-template #itemTemplate let-item="item" let-index="index">
    <!-- Default Template -->
    <editable-list-template *ngIf="!template">
        {{item?.get("item").value}} at index {{ index }}
    </editable-list-template>
        
    <!-- Custom Template -->
    <ng-container *ngIf="template">
        <ng-container *ngTemplateOutlet="template, context: { item: item, index: index, goToNext: enter(index) }"></ng-container>
    </ng-container>

    <editable-list-delete (onDelete)="delete($event, index)"></editable-list-delete>
</ng-template>
