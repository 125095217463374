<p *ngIf="!$root.appConfig.admin && serializedError.fixability == 'THIRD_PARTY_SYSTEM_CONNECTION'">
    This error is typically <strong>caused by a connection issue between {{wl.productShortName}} and another system</strong>. Your {{wl.productShortName}} administrator needs to intervene to fix the issue. <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>

 <p *ngIf="$root.appConfig.admin && serializedError.fixability == 'THIRD_PARTY_SYSTEM_CONNECTION'">
    This error is typically <strong>caused by a connection issue between {{wl.productShortName}} and another system</strong>. You need to check the connection settings and the health of the third-party system
</p>


<p *ngIf="!$root.appConfig.admin  && serializedError.fixability == 'ADMIN_SETTINGS_CONNECTIONS'">
    This error is typically <strong>caused by a configuration issue in a connection</strong> (in the administration settings). Your {{wl.productShortName}} administrator needs to intervene to fix the issue. <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>
<p *ngIf="$root.appConfig.admin && serializedError.fixability == 'ADMIN_SETTINGS_CONNECTIONS'">
    This error is typically <strong>caused by a configuration issue in a connection</strong> (in the administration settings). You need to check the connection settings
</p>

<p *ngIf="!$root.appConfig.admin  && serializedError.fixability == 'ADMIN_SETTINGS_SECURITY'">
    This error is typically <strong>caused by a configuration issue in global security settings</strong> (in the administration settings). Your {{wl.productShortName}} administrator needs to intervene to fix the issue. <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>
<p *ngIf="$root.appConfig.admin && serializedError.fixability == 'ADMIN_SETTINGS_SECURITY'">
    This error is typically <strong>caused by a configuration issue in global security settings</strong> (in the administration settings). You need to check the connection settings
</p>

<p *ngIf="!$root.appConfig.admin  && serializedError.fixability == 'ADMIN_SETTINGS_CONTAINERS'">
    This error is typically <strong>caused by a configuration issue in a container configuration</strong> (in the administration settings). Your {{wl.productShortName}} administrator needs to intervene to fix the issue. <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>
<p *ngIf="$root.appConfig.admin && serializedError.fixability == 'ADMIN_SETTINGS_CONTAINERS'">
    This error is typically <strong>caused by a configuration issue in a container configuration</strong> (in the administration settings). You need to check the container configurations
</p>

<p *ngIf="!$root.appConfig.admin  && serializedError.fixability == 'ADMIN_SETTINGS_CODEENVS'">
    This error is typically <strong>caused by a configuration issue in a code env</strong> (in the administration settings). Your {{wl.productShortName}} administrator or the owner of the code env needs to intervene to fix the issue. <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>

<p *ngIf="$root.appConfig.admin && serializedError.fixability == 'ADMIN_SETTINGS_CODEENVS'">
    This error is typically <strong>caused by a configuration issue in a code env</strong> (in the administration settings). You need to check the code env settings
</p>

<p *ngIf="serializedError.fixability == 'ADMIN_SETTINGS_USER_PROFILE'">
    This error typically <strong>requires configuration from your {{wl.productShortName}} administrator</strong>. Your {{wl.productShortName}} administrator needs to change your user profile.
</p>

<p *ngIf="serializedError.fixability == 'USER_CONFIG_DATASET'">
    This error is typically <strong>caused by a configuration issue in the dataset settings</strong>. You need to modify the affected dataset settings to fix the issue
</p>

<p *ngIf="serializedError.fixability == 'USER_CONFIG'">
    This error is typically <strong>caused by a configuration issue</strong>. You need to modify the affected settings to fix the issue
</p>

<div *ngIf="serializedError.fixability == 'USER_CONFIG_OR_BUILD'">
    <p style="margin-bottom: 0">This error is typically caused by either:</p>
    <ul>
        <li><strong>A dataset configuration issue</strong>. You need to modify the affected settings to fix the issue</li>
        <li><strong>The dataset needs to be (re)built</strong> (if it is a target in the Flow).</li>
        <li><strong>Concurrent resampling</strong>. Another user might have set different sampling settings while your worksheet was being computed.</li>
    </ul>
</div>

<p *ngIf="serializedError.fixability == 'READ_FUTURE_LOG'">
    Please read the logs for more information on this error
</p>

<p *ngIf="serializedError.fixability == 'DATA'">
    This error is typically <strong>caused by a problem in your data</strong> (i.e. data is invalid). If this data was created by {{wl.productShortName}}, you may need to <strong>rebuild it</strong>.
</p>

<p *ngIf="serializedError.fixability == 'ADMIN_SETTINGS_MISSING_PLUGIN'">
    This error is caused by a missing plugin. Please install it or remove elements that use it.
</p>

<p *ngIf="!$root.appConfig.admin && serializedError.fixability == 'ADMIN_INSTALLATION'">
    This error is typically <strong>caused by a {{wl.productShortName}} installation issue</strong>. Your administrator needs to intervene to fix the issue.  <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>

 <p *ngIf="$root.appConfig.admin && serializedError.fixability == 'ADMIN_INSTALLATION'">
    This error is typically <strong>caused by a {{wl.productShortName}} installation issue</strong>.
</p>


<p *ngIf="!$root.appConfig.admin && serializedError.fixability == 'ADMIN_TROUBLESHOOTING'">
    This error typically <strong>requires troubleshooting from your {{wl.productShortName}} administrator</strong>. Your {{wl.productShortName}} administrator needs to intervene to fix the issue.  <a *ngIf="$root.appConfig.studioAdminContact" (click)="$root.showAdminContactInfo()">How to contact your administrator?</a>
</p>

 <p *ngIf="$root.appConfig.admin && serializedError.fixability == 'ADMIN_TROUBLESHOOTING'">
    This error typically <strong>requires troubleshooting</strong>. Please refer to the below documentation for more information or contact Dataiku support.
</p>
