import { Component, forwardRef, OnChanges, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { EditableListBase } from '../editable-list/editable-list.component';
import { NG_VALUE_ACCESSOR, FormBuilder, Validators } from '@angular/forms';
import _ from 'lodash';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { TolerantFormGroup } from '@utils/tolerant-form-group';

@UntilDestroy()
@Component({
    selector: 'values-list',
    templateUrl: './values-list.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ValuesListComponent),
            multi: true
        }
    ]
})

/**
 * Editable list of string values.
 * Uses editable-list-component with a dedicated template.
 * 
 * @example
 * <values-list-component items="values"></values-list-component>
 * 
 * With values being either a list of strings [ '', ...] or a list of objects [{ valueIdentifier: '' }, ...].
 */
export class ValuesListComponent extends EditableListBase implements OnChanges, OnDestroy {
    @Input() addLabel: string = 'Add Value';
    @Input() valuePlaceholder: string = 'A value';
    @Input() valueIdentifier: string;
    @Input() required: boolean;
    // native browser autocomplete
    @Input() autocomplete?: string = 'on';
    // suggestions for mat-autocomplete
    @Input() suggestions?: string[];

    // True if the items are a list of string instead of a list of objects
    private stringsMode: boolean = false;
    private changesSubscription: Subscription;
    formBuilder: FormBuilder;
    inputKey: string;
    filteredSuggestions: string[] = [];

    writeValue(obj: any): void {
        this.items = _.cloneDeep(obj) || [];

        if (this.items && this.items.length) {
            this.computeInputKey();
            if (this.stringsMode) {
                this.items = this.items.map((item: any) =>  ({ [this.inputKey]: item }));
            }
            this.itemsFormArray && this.itemsFormArray.setValue(this.items);
        }
    }

    constructor(fb: FormBuilder) {
        super();
        this.formBuilder = fb;
    }

    // When there is no value identifier provided ie items are stored as an array of strings,
    // we set the value identifier to 'value' for the list item template, else we use the provided one.
    computeInputKey() {
        if (this.inputKey) { return; }
        if (!this.valueIdentifier) {
            this.inputKey = 'value';
            this.stringsMode = true;
        } else {
            this.inputKey = this.valueIdentifier;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.computeInputKey();

        let validators: any[] = [];

        if (this.required) {
            validators.push(Validators.required);
        }

        if (changes.suggestions) {
            this.autocomplete = this.suggestions?.length ? 'off' : 'on';
        }

        this.itemsFormArray = new FormArrayRepeat(() => {
            return new TolerantFormGroup({
                [this.inputKey]: this.formBuilder.control('', validators)
            });
        });

        if (this.items && this.items.length) {
            this.itemsFormArray.setValue(this.items.map((item: any) =>  ({ [this.inputKey]: item[this.inputKey] ? item[this.inputKey] : item })));
        }

        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }

        this.changesSubscription = this.itemsFormArray.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((items) => { this.handleChange(items) });
    }

    /** EVENT HANDLERS */
    handleChange(items: Array<Object>) {
        this.items = [];
        if (this.stringsMode) {
            items.forEach((item: any, i: number) => {
                this.items[i] = item[this.inputKey];
            });
            this.onChange(this.items);
        } else {
            this.onChange(items);
        }
    }

    handleFocus($event: FocusEvent) {
        const item = ($event.target as HTMLInputElement)?.value;

        // set current value to reset autocomplete
        this.updateSuggestions(item);

        this.onFocus.emit($event);
    }

    // for autocomplete suggestions
    updateSuggestions(entry: string) {
        this.filteredSuggestions = [];
        if (this.suggestions) {
            const lowercaseValue = entry.toLowerCase();
            for (const candidate of this.suggestions) {
                if (candidate && candidate.toLowerCase().includes(lowercaseValue)
                    && !this.items.includes(candidate)) {
                    this.filteredSuggestions.push(candidate);
                }
            }
        }
        this.filteredSuggestions.sort();
    }

    ngOnDestroy(): void { }
}
